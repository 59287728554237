import {
    getMyRSAccounts,
    SimpleRSAccountResponse,
} from '../../api/rsAccountsClient';
import { PageContext } from '../../api/utils';
import { apiRequestFailed } from './actions';

export const FETCH_MY_RS_ACCOUNTS_REQUESTED = 'FETCH_MY_RS_ACCOUNTS_REQUESTED';
export const FETCH_MY_RS_ACCOUNTS_COMPLETED = 'FETCH_MY_RS_ACCOUNTS_COMPLETED';

export const fetchMyRSAccountsRequested = () => ({
    type: FETCH_MY_RS_ACCOUNTS_REQUESTED,
});
export const fetchMyRSAccountsCompleted = (
    myAccounts: SimpleRSAccountResponse[],
) => ({
    type: FETCH_MY_RS_ACCOUNTS_COMPLETED,
    myAccounts,
});
export const fetchMyRSAccounts = (ctx: PageContext | null) => {
    return async (dispatch) => {
        dispatch(fetchMyRSAccountsRequested());
        try {
            const myAccounts = await getMyRSAccounts(ctx);
            return dispatch(fetchMyRSAccountsCompleted(myAccounts));
        } catch (e) {
            dispatch(apiRequestFailed('Failed to get rs accounts', e));
        }
    };
};
