import { SimpleRSAccountResponse } from './rsAccountsClient';
import { SimpleProductResponse } from './productsClient';
import { SimpleTaskResponse } from './tasksClient';
import { BACKEND_API_HOST, fetchApi, PageContext } from './utils';

export async function getMyActiveInstances(
    ctx: PageContext | null,
): Promise<SimpleBotInstanceResponse[]> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/api/v1/control/instances/active`,
        'GET',
        null,
        {
            source:
                ctx != null && ctx.req != null
                    ? ctx.req.url
                    : window.location.pathname,
        },
    );
}

export enum BotStatus {
    Idle = 'Idle',
    Running = 'Running',
    Paused = 'Paused',
    Terminated = 'Terminated',
}

export interface SimpleBotInstanceResponse {
    id: string;
    status: BotStatus;
    unprocessedTasks: SimpleTaskResponse[];
    script: SimpleProductResponse;
    sessions: SimpleScriptSessionResponse[];
    screenshot: string | null;
    scriptRuntime: number;
    botRuntime: number;
    createdAt: string;
    updatedAt: string;
}

export interface SimpleScriptSessionResponse {
    id: string;
    botInstance: SimpleBotInstanceResponse | null;
    script: SimpleProductResponse;
    rsAccount: SimpleRSAccountResponse | null;
    createdAt: string;
    updatedAt: string;
}
