import {
    getMyActiveInstances,
    SimpleBotInstanceResponse,
} from '../../api/instancesClient';
import { PageContext } from '../../api/utils';
import { apiRequestFailed } from './actions';

export const FETCH_MY_INSTANCES_REQUESTED = 'FETCH_MY_INSTANCES_REQUESTED';
export const FETCH_MY_INSTANCES_COMPLETED = 'FETCH_MY_INSTANCES_COMPLETED';

export const SET_START_SCRIPT_INSTANCE = 'SET_START_SCRIPT_INSTANCE';

export const setStartScriptInstance = (
    instance: SimpleBotInstanceResponse,
) => ({
    type: SET_START_SCRIPT_INSTANCE,
    instance,
});

export const fetchMyInstancesRequested = () => ({
    type: FETCH_MY_INSTANCES_REQUESTED,
});
export const fetchMyInstancesCompleted = (
    myInstances: SimpleBotInstanceResponse[],
) => ({
    type: FETCH_MY_INSTANCES_COMPLETED,
    myInstances,
});
export const fetchMyInstances = (ctx: PageContext | null) => {
    return async (dispatch) => {
        dispatch(fetchMyInstancesRequested());
        try {
            const myInstances = await getMyActiveInstances(ctx);
            return dispatch(fetchMyInstancesCompleted(myInstances));
        } catch (e) {
            dispatch(apiRequestFailed('Failed to search scripts', e));
        }
    };
};
