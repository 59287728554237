import { BACKEND_API_HOST, fetchApi, PageContext } from './utils';

export async function getMyRSAccounts(
    ctx: PageContext | null,
): Promise<SimpleRSAccountResponse[]> {
    return await fetchApi(
        ctx,
        `${BACKEND_API_HOST}/api/v1/control/accounts`,
        'GET',
        null,
        null,
    );
}

export interface SimpleRSAccountResponse {
    id: string;
    nickname: string;
    createdAt: string;
    updatedAt: string;
}
